import {Formik, Field, Form, FieldArray} from 'formik';
import DateView  from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';

function validateCheckbox(value) {
    let error;
    if (!value) {
        error = 'Please Check This Box to Certify all Persons are 18 Years Old or Older';
    }
    return error;
}
function validateEmail(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
    return error;
}
function validateGAID(value) {
    let error;
    if (!value) {
        error = 'Required';
    }
    return error;
}
function validatePhoneNumber(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^([0|+[0-9]{1,5})?([0-9]{10})$/.test(value)) {
        error = 'Invalid Phone Number';
    }
    return error;
}
/* eslint-disable no-control-regex, no-useless-escape */
function validateDate(value) {
    let error;
    if (!value) {
        error = 'Required';
    }
    return error;
}

function validatePrefix(value) {
    let error;
    if (value && !/^[^\x00-\x1F!-&(-,\/:-@[-_\x7B-\x7F]{1,50}$/.test(value)) {
        error = 'Invalid Name Prefix';
    }
    return error;
}

function validateFirstName(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[^\x00-\x1F!-&(-,\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid First Name';
    }
    return error;
}

function validateMiddleName(value) {
    let error;
    if (value && !/^[^\x00-\x1F!-&(-,\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid Middle Name';
    }
    return error;
}

function validateLastName(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[^\x00-\x1F!-&(-,\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid Last Name';
    }
    return error;
}

function validateSuffix(value) {
    let error;
    if (value && !/^[^\x00-\x1F!-&(-,\/:-@[-_\x7B-\x7F]{1,50}$/.test(value)) {
        error = 'Invalid Name Suffix';
    }
    return error;
}
function validateAddressOne(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[^\x00-\x1F!-&(-+\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid Address One';
    }
    return error;
}
function validateAddressTwo(value) {
    let error;
    if (value && !/^[^\x00-\x1F!-&(-+\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid Address Two';
    }
    return error;
}
function validateCity(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[^\x00-\x1F!-&(-+\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid City Name';
    }
    return error;
}
function validateZip(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[^\x00-\x1F!-&(-+\/:-@[-_\x7B-\x7F]{1,100}$/.test(value)) {
        error = 'Invalid Zip Code';
    }
    return error;
}
/* eslint-enable no-control-regex, no-useless-escape */

function AdditionalDataForm({plan, callback, numEnrollment = 0}) {
    // const [birthDate, setBirthDate] = useState(null);
    const handleSubmitBtnClick = (formErrors) => {
        if (formErrors && formErrors.length > 0) {
            console.log('handleSubmitBtnClick ran', formErrors);
        }
    };
    const initialValues = {
        forms: [
            {
                birthDate: null,
                name: {
                    prefix: "",
                    first: "",
                    middle: "",
                    last: "",
                    suffix: ""
                },
                otherNames: [],
                email: '',
                phoneNumber: '',
                gaid: '',
                address: {
                    address: '',
                    addressTwo: '',
                    city: "",
                    state: "AK",
                    zip: '',
                    country: "US",
                    current: true
                },
                addresses: [],
                phoneNumbers: [],
                emailAddresses: []
            }
        ],
        eighteenOrOlder: false
    };

/*    const handleChange = (e) => {
        console.log('e', e);
    }*/
    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    // await new Promise((r) => setTimeout(r, 500));
                    // alert(JSON.stringify(values, null, 2));
                    callback(values);
                    console.log('form value on submit', values);
                }}
                render={({ values, errors, touched, handleReset, setFieldValue }) => {
                    return (
                        <Form className="container-fluid">
                            <FieldArray
                                name="forms"
                                render={({ insert, remove, push }) => (
                                    <Accordion defaultActiveKey="0" alwaysOpen>
                                        {values.forms.length > 0 &&
                                        values.forms.map((form, index) => (
                                        <Accordion.Item eventKey={`${index}`} key={index}>
                                            <Accordion.Header>Person {index + 1}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label className="control-label" htmlFor={`forms.${index}.birthDate`}>Birth Date *</label>
                                                            <Field name={`forms.${index}.birthDate`} validate={validateDate}>
                                                                {({ form, field }) => {
                                                                    const { setFieldValue } = form
                                                                    const { value } = field
                                                                    return (
                                                                        <DateView
                                                                            id={`forms.${index}.birthDate`}
                                                                            placeholderText="mm/dd/yyyy *"
                                                                            className="form-control"
                                                                            {...field}
                                                                            selected={value}
                                                                            onChange={val => setFieldValue(`forms.${index}.birthDate`, val)}
                                                                        />
                                                                    )
                                                                }}
                                                            </Field>
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].birthDate &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].birthDate && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].birthDate}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.name.prefix`}>Name Prefix</label>
                                                            <Field
                                                                name={`forms.${index}.name.prefix`}
                                                                validate={validatePrefix}
                                                                type="text"
                                                                className="form-control"
                                                                id={`forms.${index}.name.prefix`}
                                                                placeholderText="Enter your name prefix."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].name &&
                                                            errors.forms[index].name.prefix &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].name &&
                                                            touched.forms[index].name.prefix && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].name.prefix}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.name.first`}>First Name *</label>
                                                            <Field
                                                                name={`forms.${index}.name.first`}
                                                                type="text"
                                                                validate={validateFirstName}
                                                                id={`forms.${index}.name.first`}
                                                                className="form-control"
                                                                placeholderText="Enter your name first name. *"
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].name &&
                                                            errors.forms[index].name.first &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].name &&
                                                            touched.forms[index].name.first && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].name.first}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.name.middle`}>Middle Name</label>
                                                            <Field
                                                                name={`forms.${index}.name.middle`}
                                                                type="text"
                                                                className="form-control"
                                                                validate={validateMiddleName}
                                                                id={`forms.${index}.name.middle`}
                                                                placeholderText="Enter your name middle name."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].name &&
                                                            errors.forms[index].name.middle &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].name &&
                                                            touched.forms[index].name.middle && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].name.middle}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.name.last`}>Last Name *</label>
                                                            <Field
                                                                name={`forms.${index}.name.last`}
                                                                type="text"
                                                                validate={validateLastName}
                                                                className="form-control"
                                                                id={`forms.${index}.name.last`}
                                                                placeholderText="Enter your name last name *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].name &&
                                                            errors.forms[index].name.last &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].name &&
                                                            touched.forms[index].name.last && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].name.last}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.name.suffix`}>Name Suffix</label>
                                                            <Field
                                                                name={`forms.${index}.name.suffix`}
                                                                type="text"
                                                                validate={validateSuffix}
                                                                className="form-control"
                                                                id={`forms.${index}.name.suffix`}
                                                                placeholderText="Enter your name name suffix."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].name &&
                                                            errors.forms[index].name.suffix &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].name &&
                                                            touched.forms[index].name.suffix && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].name.suffix}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.email`}>Email *</label>
                                                            <Field
                                                                name={`forms.${index}.email`}
                                                                type="email"
                                                                id={`forms.${index}.email`}
                                                                className="form-control"
                                                                validate={validateEmail}
                                                                placeholderText="Enter your email *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].email &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].email && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].email}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.phoneNumber`}>Phone Number *</label>
                                                            <Field
                                                                name={`forms.${index}.phoneNumber`}
                                                                type="tel"
                                                                id={`forms.${index}.phoneNumber`}
                                                                validate={validatePhoneNumber}
                                                                className="form-control"
                                                                placeholderText="Enter your phone number e.g. 1234567890 *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].phoneNumber &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].phoneNumber && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].phoneNumber}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.gaid`}>Phone MAID *</label>
                                                            <Field
                                                                name={`forms.${index}.gaid`}
                                                                type="text"
                                                                validate={validateGAID}
                                                                className="form-control"
                                                                id={`forms.${index}.gaid`}
                                                                placeholderText="Enter your phone's GAID *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].gaid &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].gaid && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].gaid}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.address.address`}>Current Address Line One *</label>
                                                            <Field
                                                                name={`forms.${index}.address.address`}
                                                                type="text"
                                                                validate={validateAddressOne}
                                                                className="form-control"
                                                                id={`forms.${index}.address.address`}
                                                                placeholderText="Enter your Current Address Line One *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].address &&
                                                            errors.forms[index].address.address &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].address &&
                                                            touched.forms[index].address.address && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].address.address}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.address.addressTwo`}>Current Address Line Two</label>
                                                            <Field
                                                                name={`forms.${index}.address.addressTwo`}
                                                                type="text"
                                                                validate={validateAddressTwo}
                                                                className="form-control"
                                                                id={`forms.${index}.address.addressTwo`}
                                                                placeholderText="Enter your Current Address Line Two."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].address &&
                                                            errors.forms[index].address.addressTwo &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].address &&
                                                            touched.forms[index].address.addressTwo && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].address.addressTwo}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.address.city`}>Current City *</label>
                                                            <Field
                                                                name={`forms.${index}.address.city`}
                                                                type="text"
                                                                validate={validateCity}
                                                                className="form-control"
                                                                id={`forms.${index}.address.city`}
                                                                placeholderText="Enter your Current City *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].address &&
                                                            errors.forms[index].address.city &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].address &&
                                                            touched.forms[index].address.city && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].address.city}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.address.state`}>Current State *</label>
                                                            <Field
                                                                aria-label="Select Your Current State *"
                                                                as="select"
                                                                className="form-select"
                                                                name={`forms.${index}.address.state`}
                                                                id={`forms.${index}.address.state`}
                                                                placeholderText="Select your Current State *.">
                                                                <option value="AK">Alaska</option>
                                                                <option value="AL">Alabama</option>
                                                                <option value="AR">Arkansas</option>
                                                                <option value="AZ">Arizona</option>
                                                                <option value="CA">California</option>
                                                                <option value="CO">Colorado</option>
                                                                <option value="CT">Connecticut</option>
                                                                <option value="DC">District of Columbia</option>
                                                                <option value="DE">Delaware</option>
                                                                <option value="FL">Florida</option>
                                                                <option value="GA">Georgia</option>
                                                                <option value="HI">Hawaii</option>
                                                                <option value="IA">Iowa</option>
                                                                <option value="ID">Idaho</option>
                                                                <option value="IL">Illinois</option>
                                                                <option value="IN">Indiana</option>
                                                                <option value="KS">Kansas</option>
                                                                <option value="KY">Kentucky</option>
                                                                <option value="LA">Louisiana</option>
                                                                <option value="MA">Massachusetts</option>
                                                                <option value="MD">Maryland</option>
                                                                <option value="ME">Maine</option>
                                                                <option value="MI">Michigan</option>
                                                                <option value="MN">Minnesota</option>
                                                                <option value="MO">Missouri</option>
                                                                <option value="MS">Mississippi</option>
                                                                <option value="MT">Montana</option>
                                                                <option value="NC">North Carolina</option>
                                                                <option value="ND">North Dakota</option>
                                                                <option value="NE">Nebraska</option>
                                                                <option value="NH">New Hampshire</option>
                                                                <option value="NJ">New Jersey</option>
                                                                <option value="NM">New Mexico</option>
                                                                <option value="NV">Nevada</option>
                                                                <option value="NY">New York</option>
                                                                <option value="OH">Ohio</option>
                                                                <option value="OK">Oklahoma</option>
                                                                <option value="OR">Oregon</option>
                                                                <option value="PA">Pennsylvania</option>
                                                                <option value="PR">Puerto Rico</option>
                                                                <option value="RI">Rhode Island</option>
                                                                <option value="SC">South Carolina</option>
                                                                <option value="SD">South Dakota</option>
                                                                <option value="TN">Tennessee</option>
                                                                <option value="TX">Texas</option>
                                                                <option value="UT">Utah</option>
                                                                <option value="VA">Virginia</option>
                                                                <option value="VT">Vermont</option>
                                                                <option value="WA">Washington</option>
                                                                <option value="WI">Wisconsin</option>
                                                                <option value="WV">West Virginia</option>
                                                                <option value="WY">Wyoming</option>
                                                            </Field>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 mb-2">
                                                            <label htmlFor={`forms.${index}.address.zip`}>Current Zip Code *</label>
                                                            <Field
                                                                name={`forms.${index}.address.zip`}
                                                                type="text"
                                                                validate={validateZip}
                                                                className="form-control"
                                                                id={`forms.${index}.address.zip`}
                                                                placeholderText="Enter your Current Zip Code *."
                                                            />
                                                            {errors.forms &&
                                                            errors.forms[index] &&
                                                            errors.forms[index].address &&
                                                            errors.forms[index].address.zip &&
                                                            touched.forms &&
                                                            touched.forms[index] &&
                                                            touched.forms[index].address &&
                                                            touched.forms[index].address.zip && (
                                                                <div className="field-error">
                                                                    {errors.forms[index].address.zip}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mt-4 p-3">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <h2>Other Phone Numbers</h2>
                                                            <FieldArray name={`forms[${index}].phoneNumbers`}
                                                                        render={({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.forms[index].phoneNumbers.map((task, taskIndex) => (
                                                                                    <div className="d-flex flex-row mb-2">
                                                                                        <div>
                                                                                            <label htmlFor={`forms.${index}.phoneNumbers.${taskIndex}`}>Phone Number</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.phoneNumbers.${taskIndex}`}
                                                                                                type="tel"
                                                                                                className="form-control"
                                                                                                validate={validatePhoneNumber}
                                                                                                id={`forms.${index}.phoneNumbers.${taskIndex}`}
                                                                                                placeholderText="Enter the phone number e.g. 1234567890 *."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].phoneNumbers &&
                                                                                            errors.forms[index].phoneNumbers[taskIndex] &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].phoneNumbers &&
                                                                                            touched.forms[index].phoneNumbers[taskIndex] && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].phoneNumbers[taskIndex]}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <button className="btn btn-danger avantis-minus-form-data" type="button"  onClick={() => remove(taskIndex)}><i className="flaticon-minus"></i></button>
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-success" type="button" onClick={() => push('')}><i className="flaticon-add"></i>Add new phone number<span></span></button>
                                                                            </div>)}/>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <h2>Other Email Addresses</h2>
                                                            <FieldArray name={`forms[${index}].emailAddresses`}
                                                                        render={({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.forms[index].emailAddresses.map((task, taskIndex) => (
                                                                                    <div className="d-flex flex-row mb-2">
                                                                                        <div>
                                                                                            <label htmlFor={`forms.${index}.emailAddresses.${taskIndex}`}>Email Address</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.emailAddresses.${taskIndex}`}
                                                                                                type="email"
                                                                                                validate={validateEmail}
                                                                                                className="form-control"
                                                                                                id={`forms.${index}.emailAddresses.${taskIndex}`}
                                                                                                placeholderText="Enter the email address."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].emailAddresses &&
                                                                                            errors.forms[index].emailAddresses[taskIndex] &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].emailAddresses &&
                                                                                            touched.forms[index].emailAddresses[taskIndex] && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].emailAddresses[taskIndex]}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <button className="btn btn-danger avantis-minus-form-data" type="button"  onClick={() => remove(taskIndex)}><i className="flaticon-minus"></i></button>
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-success" type="button" onClick={() => push('')}><i className="flaticon-add"></i>Add new email address<span></span></button>
                                                                            </div>)}/>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <h2>Other Names</h2>
                                                            <FieldArray name={`forms[${index}].otherNames`}
                                                                        render={({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.forms[index].otherNames.map((task, taskIndex) => (
                                                                                    <div className="d-flex flex-row mb-2">
                                                                                        <div>
                                                                                            <label htmlFor={`forms.${index}.otherNames.${taskIndex}.prefix`}>Name Prefix</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.otherNames.${taskIndex}.prefix`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validatePrefix}
                                                                                                id={`forms.${index}.otherNames.${taskIndex}.prefix`}
                                                                                                placeholderText="Enter the name prefix."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].otherNames &&
                                                                                            errors.forms[index].otherNames[taskIndex] &&
                                                                                            errors.forms[index].otherNames[taskIndex].prefix &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].otherNames &&
                                                                                            touched.forms[index].otherNames[taskIndex] &&
                                                                                            touched.forms[index].otherNames[taskIndex].prefix && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].otherNames[taskIndex].prefix}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.otherNames.${taskIndex}.first`}>First Name *</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.otherNames.${taskIndex}.first`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateFirstName}
                                                                                                id={`forms.${index}.otherNames.${taskIndex}.first`}
                                                                                                placeholderText="Enter the first name *."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].otherNames &&
                                                                                            errors.forms[index].otherNames[taskIndex] &&
                                                                                            errors.forms[index].otherNames[taskIndex].first &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].otherNames &&
                                                                                            touched.forms[index].otherNames[taskIndex] &&
                                                                                            touched.forms[index].otherNames[taskIndex].first && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].otherNames[taskIndex].first}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.otherNames.${taskIndex}.middle`}>Middle Name</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.otherNames.${taskIndex}.middle`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateMiddleName}
                                                                                                id={`forms.${index}.otherNames.${taskIndex}.middle`}
                                                                                                placeholderText="Enter the middle name."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].otherNames &&
                                                                                            errors.forms[index].otherNames[taskIndex] &&
                                                                                            errors.forms[index].otherNames[taskIndex].middle &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].otherNames &&
                                                                                            touched.forms[index].otherNames[taskIndex] &&
                                                                                            touched.forms[index].otherNames[taskIndex].middle && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].otherNames[taskIndex].middle}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.otherNames.${taskIndex}.last`}>Last Name *</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.otherNames.${taskIndex}.last`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateLastName}
                                                                                                id={`forms.${index}.otherNames.${taskIndex}.last`}
                                                                                                placeholderText="Enter the last name. *"
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].otherNames &&
                                                                                            errors.forms[index].otherNames[taskIndex] &&
                                                                                            errors.forms[index].otherNames[taskIndex].last &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].otherNames &&
                                                                                            touched.forms[index].otherNames[taskIndex] &&
                                                                                            touched.forms[index].otherNames[taskIndex].last && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].otherNames[taskIndex].last}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.otherNames.${taskIndex}.suffix`}>Name Suffix</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.otherNames.${taskIndex}.suffix`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateSuffix}
                                                                                                id={`forms.${index}.otherNames.${taskIndex}.suffix`}
                                                                                                placeholderText="Enter the name suffix."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].otherNames &&
                                                                                            errors.forms[index].otherNames[taskIndex] &&
                                                                                            errors.forms[index].otherNames[taskIndex].suffix &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].otherNames &&
                                                                                            touched.forms[index].otherNames[taskIndex] &&
                                                                                            touched.forms[index].otherNames[taskIndex].suffix && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].otherNames[taskIndex].suffix}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <button className="btn btn-danger avantis-minus-form-data" type="button"  onClick={() => remove(taskIndex)}><i className="flaticon-minus"></i></button>
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-success" type="button" onClick={() => push({
                                                                                    prefix: "",
                                                                                    first: "",
                                                                                    middle: "",
                                                                                    last: "",
                                                                                    suffix: ""
                                                                                })}><i className="flaticon-add"></i>Add Name<span></span></button>
                                                                            </div>)}/>
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <h2>Other Addresses</h2>
                                                            <FieldArray name={`forms[${index}].addresses`}
                                                                        render={({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.forms[index].addresses.map((task, taskIndex) => (
                                                                                    <div className="d-flex flex-row mb-2">
                                                                                        <div>
                                                                                            <label htmlFor={`forms.${index}.addresses.${taskIndex}.address`}>Address Line One *</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.addresses.${taskIndex}.address`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateAddressOne}
                                                                                                id={`forms.${index}.addresses.${taskIndex}.address`}
                                                                                                placeholderText="Enter the address line one *."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].addresses &&
                                                                                            errors.forms[index].addresses[taskIndex] &&
                                                                                            errors.forms[index].addresses[taskIndex].address &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].addresses &&
                                                                                            touched.forms[index].addresses[taskIndex] &&
                                                                                            touched.forms[index].addresses[taskIndex].address && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].addresses[taskIndex].address}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.addresses.${taskIndex}.addressTwo`}>Address Line Two</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.addresses.${taskIndex}.addressTwo`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateAddressTwo}
                                                                                                id={`forms.${index}.addresses.${taskIndex}.addressTwo`}
                                                                                                placeholderText="Enter the address line two."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].addresses &&
                                                                                            errors.forms[index].addresses[taskIndex] &&
                                                                                            errors.forms[index].addresses[taskIndex].addressTwo &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].addresses &&
                                                                                            touched.forms[index].addresses[taskIndex] &&
                                                                                            touched.forms[index].addresses[taskIndex].addressTwo && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].addresses[taskIndex].addressTwo}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.addresses.${taskIndex}.city`}>City *</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.addresses.${taskIndex}.city`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateCity}
                                                                                                id={`forms.${index}.addresses.${taskIndex}.city`}
                                                                                                placeholderText="Enter the city. *"
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].addresses &&
                                                                                            errors.forms[index].addresses[taskIndex] &&
                                                                                            errors.forms[index].addresses[taskIndex].city &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].addresses &&
                                                                                            touched.forms[index].addresses[taskIndex] &&
                                                                                            touched.forms[index].addresses[taskIndex].city && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].addresses[taskIndex].city}
                                                                                                </div>
                                                                                            )}
                                                                                            <label htmlFor={`forms.${index}.addresses.${taskIndex}.state`}>State</label>
                                                                                            <Field
                                                                                                aria-label="Select Your State"
                                                                                                as="select"
                                                                                                className="form-select"
                                                                                                name={`forms.${index}.addresses.${taskIndex}.state`}
                                                                                                id={`forms.${index}.addresses.${taskIndex}.state`}
                                                                                                placeholderText="Select the state.">
                                                                                                <option value="AK">Alaska</option>
                                                                                                <option value="AL">Alabama</option>
                                                                                                <option value="AR">Arkansas</option>
                                                                                                <option value="AZ">Arizona</option>
                                                                                                <option value="CA">California</option>
                                                                                                <option value="CO">Colorado</option>
                                                                                                <option value="CT">Connecticut</option>
                                                                                                <option value="DC">District of Columbia</option>
                                                                                                <option value="DE">Delaware</option>
                                                                                                <option value="FL">Florida</option>
                                                                                                <option value="GA">Georgia</option>
                                                                                                <option value="HI">Hawaii</option>
                                                                                                <option value="IA">Iowa</option>
                                                                                                <option value="ID">Idaho</option>
                                                                                                <option value="IL">Illinois</option>
                                                                                                <option value="IN">Indiana</option>
                                                                                                <option value="KS">Kansas</option>
                                                                                                <option value="KY">Kentucky</option>
                                                                                                <option value="LA">Louisiana</option>
                                                                                                <option value="MA">Massachusetts</option>
                                                                                                <option value="MD">Maryland</option>
                                                                                                <option value="ME">Maine</option>
                                                                                                <option value="MI">Michigan</option>
                                                                                                <option value="MN">Minnesota</option>
                                                                                                <option value="MO">Missouri</option>
                                                                                                <option value="MS">Mississippi</option>
                                                                                                <option value="MT">Montana</option>
                                                                                                <option value="NC">North Carolina</option>
                                                                                                <option value="ND">North Dakota</option>
                                                                                                <option value="NE">Nebraska</option>
                                                                                                <option value="NH">New Hampshire</option>
                                                                                                <option value="NJ">New Jersey</option>
                                                                                                <option value="NM">New Mexico</option>
                                                                                                <option value="NV">Nevada</option>
                                                                                                <option value="NY">New York</option>
                                                                                                <option value="OH">Ohio</option>
                                                                                                <option value="OK">Oklahoma</option>
                                                                                                <option value="OR">Oregon</option>
                                                                                                <option value="PA">Pennsylvania</option>
                                                                                                <option value="PR">Puerto Rico</option>
                                                                                                <option value="RI">Rhode Island</option>
                                                                                                <option value="SC">South Carolina</option>
                                                                                                <option value="SD">South Dakota</option>
                                                                                                <option value="TN">Tennessee</option>
                                                                                                <option value="TX">Texas</option>
                                                                                                <option value="UT">Utah</option>
                                                                                                <option value="VA">Virginia</option>
                                                                                                <option value="VT">Vermont</option>
                                                                                                <option value="WA">Washington</option>
                                                                                                <option value="WI">Wisconsin</option>
                                                                                                <option value="WV">West Virginia</option>
                                                                                                <option value="WY">Wyoming</option>
                                                                                            </Field>
                                                                                            <label htmlFor={`forms.${index}.addresses.${taskIndex}.zip`}>Zip Code *</label>
                                                                                            <Field
                                                                                                name={`forms.${index}.addresses.${taskIndex}.zip`}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                validate={validateZip}
                                                                                                id={`forms.${index}.addresses.${taskIndex}.zip`}
                                                                                                placeholderText="Enter the zip code *."
                                                                                            />
                                                                                            {errors.forms &&
                                                                                            errors.forms[index] &&
                                                                                            errors.forms[index].addresses &&
                                                                                            errors.forms[index].addresses[taskIndex] &&
                                                                                            errors.forms[index].addresses[taskIndex].zip &&
                                                                                            touched.forms &&
                                                                                            touched.forms[index] &&
                                                                                            touched.forms[index].addresses &&
                                                                                            touched.forms[index].addresses[taskIndex] &&
                                                                                            touched.forms[index].addresses[taskIndex].zip && (
                                                                                                <div className="field-error">
                                                                                                    {errors.forms[index].addresses[taskIndex].zip}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <button className="btn btn-danger avantis-minus-form-data" type="button"  onClick={() => remove(taskIndex)}><i className="flaticon-minus"></i></button>
                                                                                    </div>
                                                                                ))}
                                                                                <button className="btn btn-success" type="button" onClick={() => push({
                                                                                    address: '',
                                                                                    addressTwo: '',
                                                                                    city: "",
                                                                                    state: "AK",
                                                                                    zip: '',
                                                                                    country: "US"
                                                                                })}><i className="flaticon-add"></i>Add Address<span></span></button>
                                                                            </div>)}/>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            {
                                                                index !== 0 && <div className="col">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger "
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <i className="flaticon-minus"></i>
                                                                        Remove Member
                                                                        <span></span>
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        ))}
                                        {
                                            plan === 'avantis-privacy-family' && values.forms.length + numEnrollment < 4 && <button
                                                type="button"
                                                className="mt-4 btn btn-success"
                                                onClick={() => push({
                                                    birthDate: null,
                                                    name: {
                                                        prefix: "",
                                                        first: "",
                                                        middle: "",
                                                        last: "",
                                                        suffix: ""
                                                    },
                                                    otherNames: [],
                                                    email: '',
                                                    phoneNumber: '',
                                                    gaid: '',
                                                    address: {
                                                        address: '',
                                                        addressTwo: '',
                                                        city: "",
                                                        state: "AK",
                                                        zip: '',
                                                        country: "US"
                                                    },
                                                    addresses: [],
                                                    phoneNumbers: [],
                                                    emailAddresses: []
                                                })}
                                            >
                                                <i className="flaticon-add"></i>
                                                Add Member
                                                <span></span>
                                            </button>
                                        }
                                    </Accordion>
                                )}
                            />
                            <div className="card mt-4 p-3">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <label className="me-2" htmlFor={`eighteenOrOlder`}>All Persons are 18 years of age or older</label>
                                        <Field
                                            name={`eighteenOrOlder`}
                                            type="checkbox"
                                            className="checkbox-inline avantis-vertical-align-middle"
                                            id={`eighteenOrOlder`}
                                            validate={validateCheckbox}
                                        />
                                        {errors.eighteenOrOlder &&
                                        touched.eighteenOrOlder && (
                                            <div className="field-error">
                                                {errors.eighteenOrOlder}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-sm-12 mt-1">
                                        <button className="default-btn" type="submit" onClick={() => {handleSubmitBtnClick(errors.forms)}}>
                                            <i className="flaticon-tick"></i>
                                            Submit
                                            <span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    );
}

export default AdditionalDataForm;