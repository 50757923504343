import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/animate.min.css';
import './styles/boxicons.min.css';
import './styles/flaticon.css';
import './styles/responsive.css';
import './styles/style.css';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const root = ReactDOM.createRoot(document.getElementById('root'));

const PUBLIC_KEY = "pk_test_51Lc86nEaXZZjgFUWbRFyaReb0xmKaP9JApTWSzEVl2akBtqirQJKDBYvvjeXxJyFZ3JfCeFz8UOXrZWaxQJ7n4qc00hVlA2kjX";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

root.render(
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
    >
        <Elements stripe={stripeTestPromise}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
        </ Elements>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
