import ProgressBar from "./ProgressBar";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";


function ProgressBarContainer({callback, domain, freeScanData}) {

    const { user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/create-free-scan',
                headers: {Authorization: `${r}`},
                data: {
                    free_scan_data: freeScanData,
                    userId: user.sub,
                }
            };
            axios.request(options).then(function (response) {
                console.log('useEffect ran');
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(() => {
            console.log('Error Posting Form Data');
        });
    });

    return (
        <div>
            <ProgressBar callback={callback} />
        </div>
    );
}

export default ProgressBarContainer;