import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";

function BrandYourselfResponsiveTable({domain}) {
    const { user, getAccessTokenSilently } = useAuth0();
    const [payload, setPayload] = useState([]);
    const [useEffectRan, setUseEffectRan] = useState(false);

    useEffect(() => {
        console.log('useEffect ran brandyourself table');
        if (!useEffectRan) {
            getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            }).then(r => {
                const options = {
                    method: 'GET',
                    url: 'https://api.avantisprivacy.com/fetch-webhook-payload',
                    headers: {Authorization: `${r}`},
                    params: {
                        userId: user.sub,
                    }
                };
                axios.request(options).then(function (response) {
                    console.log('response fetch payload', response)
                    setPayload(response.data);
                }).catch(function (error) {
                    console.error(error);
                });
            }).catch(() => {
                console.log('Error Posting Form Data');
            });
            setUseEffectRan(true);
        }
    }, [useEffectRan, getAccessTokenSilently, domain, user.sub]);
    return (
        <div className="container pt-100 pb-100">
            <div className="row">
                <div className="col-xs-12">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Broker</th>
                            <th>Name</th>
                            <th>Age</th>
                            <th>Score</th>
                            <th>Removed</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            payload.map((record) => {
                                return (<tr>
                                    <td>{record.brokerId}</td>
                                    <td>{record.fullName}</td>
                                    <td>{record.age}</td>
                                    <td>{record.score}</td>
                                    <td>{record.verifiedAt ? "Removed" : "Pending"}</td>
                                </tr>);
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default BrandYourselfResponsiveTable;