import './ProgressBar.css';
import {useEffect, useState} from "react";

function ProgressBar({callback}) {
    let i = 0;
    let bar;
    const makeProgress = () => {
        if (bar && i >= 100) {
            console.log('call callback ran');
            callback()
            return;
        }
        if(bar && i < 100){
            i = i + 1;
            bar.style.width = i + "%";
            bar.innerText = i + "%";
        }

        // Wait for sometime before running this script again
        setTimeout(makeProgress, 1000);
    }
    makeProgress();


    useEffect(() => {
        bar = document.querySelector(".progress-bar");
    }, []);
    return (
        <div className="progress">
            <div className="progress-bar progress-bar-striped"></div>
        </div>
    );
}

export default ProgressBar;