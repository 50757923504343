import React from 'react'
import {Button} from "react-bootstrap";

import locationBroker from '../images/services/Avantis-LocationDataBroker-wide-cut-trim.png';
import medicalBroker from '../images/services/Avantis-HealthDataBroker-wide-cut-trim.png';
import politicalBroker from '../images/services/Avantis-PoliticalDataBroker-wide-cut.png';

const Pricing = (props) => {
    const [individualMonthly, setIndividualMonthly] = React.useState(true);
    const [familyMonthly, setFamilyMonthly] = React.useState(true);
    const subHeader = props.subHeader ? props.subHeader : 'It has never been more essential to protect your data from profiteering broker companies. Whatever your budget, Avantis Privacy is sure to have the perfect plan to suit your needs.';
    const mainHeader = props.mainHeader ? props.mainHeader : 'Our Pricing Plans';
    const pOne = props.pOne ? props.pOne : 'Every citizen should have access to data broker removal services to stop the distribution and sale of their information.  Citizens have the RIGHT to delete their information - but not the ABILITY to actually make it happen. This is the work of surveillance capitalism and politicians.';
    const pTwo = props.pTwo ? props.pTwo : 'Avantis can help. But there is overhead in keeping up with the devious ways of data villains.';
    const paymentOptionIndividualMonthlyBtnStyles = individualMonthly ? 'btn btn-primary avantis-privacy-pricing-btn avantis-privacy-pricing-btn-selected' : 'btn btn-primary avantis-privacy-pricing-btn';
    const paymentOptionIndividualYearlyBtnStyles = individualMonthly ? 'btn btn-primary avantis-privacy-pricing-btn' : 'btn btn-primary avantis-privacy-pricing-btn avantis-privacy-pricing-btn-selected';
    const paymentOptionFamilyMonthlyBtnStyles = familyMonthly ? 'btn btn-primary avantis-privacy-pricing-btn avantis-privacy-pricing-btn-selected' : 'btn btn-primary avantis-privacy-pricing-btn';
    const paymentOptionFamilyYearlyBtnStyles = familyMonthly ? 'btn btn-primary avantis-privacy-pricing-btn' : 'btn btn-primary avantis-privacy-pricing-btn avantis-privacy-pricing-btn-selected';

    return (
        <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
            <div className="container">
                <div className="section-title">
                    <h2>{mainHeader}</h2>
                    <p dangerouslySetInnerHTML={{ __html: subHeader }} />
                </div>
                <p>{pOne}</p>
                <p>{pTwo}</p>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="avantis-pricing-button-group-container">
                            <div role="group" className="avantis-pricing-btn-group" aria-label="Select Monthly or Yearly Price for Data Purge">
                                <button onClick={() => {setIndividualMonthly(true)}} type="button" className={paymentOptionIndividualMonthlyBtnStyles}>Monthly</button>
                                <button onClick={() => {setIndividualMonthly(false)}} type="button" className={paymentOptionIndividualYearlyBtnStyles}>Yearly</button>
                            </div>
                        </div>
                        <div className="single-pricing-box avantis-privacy-year-month-single-pricing-box">
                            <div className="pricing-header avantis-pricing-header">
                                <h3>Data Purge</h3>
                            </div>

                            <div className="price avantis-price">
                                <sup>$</sup> {individualMonthly ? '12.99' : '99.95'} <sub>{individualMonthly ? 'mo - Switch to Yearly Save 36%' : 'yr'}</sub>
                            </div>

                            <div className="icon">
                                <img className="avantis-pricing-img" src={locationBroker} alt="image" />
                                <img className="avantis-pricing-img avantis-pricing-political-broker-img" src={politicalBroker} alt="image" />
                                <img className="avantis-pricing-img avantis-pricing-healthcare-broker-img" src={medicalBroker} alt="image" />
                            </div>

                            <ul className="avantis-are-you-exposed-list avantis-pricing-list">
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔷</i> Covers one person
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>⭐</i> Removes location from 35 brokers<br/>
                                    <span className="avantis-pricing-list-item-detail-span">exclusive tech purges your location data</span><br/>
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>⭐</i> Removes data from 300+ brokers<br/>
                                    <span className="avantis-pricing-list-item-detail-span">personal, location, political, health, more</span><br/>
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔷</i> Continuous monitoring for a year
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Avantis Web App
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Premium support
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Exclusive Data Privacy Manuals
                                </li>
                            </ul>

                            <div className="btn-box">
                                <Button className="default-btn" onClick={event => props.handleClick(event, 'avantis-privacy-individual')}>
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="avantis-pricing-button-group-container">
                            <div role="group" className="avantis-pricing-btn-group" aria-label="Select Monthly or Yearly Price for Data Purge Family">
                                <button onClick={() => {setFamilyMonthly(true)}} type="button" className={paymentOptionFamilyMonthlyBtnStyles}>Monthly</button>
                                <button onClick={() => {setFamilyMonthly(false)}} type="button" className={paymentOptionFamilyYearlyBtnStyles}>Yearly</button>
                            </div>
                        </div>
                        <div className="single-pricing-box avantis-privacy-year-month-single-pricing-box">
                            <div className="pricing-header avantis-pricing-header">
                                <h3>Data Purge Family</h3>
                            </div>

                            <div className="price avantis-price">
                                <sup>$</sup> {familyMonthly ? '18.99' : '150.06'} <sub>{familyMonthly ? 'mo - Switch to Yearly Save 34%' : 'yr'}</sub>
                            </div>

                            <div className="icon">
                                <img className="avantis-pricing-img" src={locationBroker} alt="image" />
                                <img className="avantis-pricing-img avantis-pricing-political-broker-img" src={politicalBroker} alt="image" />
                                <img className="avantis-pricing-img avantis-pricing-healthcare-broker-img" src={medicalBroker} alt="image" />
                            </div>

                            <ul className="avantis-are-you-exposed-list avantis-pricing-list">
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔷</i> Covers one family, up to <strong>4</strong> within a household
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>⭐</i> Removes location from 35 brokers<br/>
                                    <span className="avantis-pricing-list-item-detail-span">exclusive tech purges your location data</span><br/>
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>⭐</i> Removes data from 300+ brokers<br/>
                                    <span className="avantis-pricing-list-item-detail-span">personal, location, political, health, more</span><br/>
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔷</i> Continuous monitoring for a year
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Avantis Web App
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Premium support
                                </li>
                                <li className="avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list-item avantis-pricing-list-item">
                                    <i className='bx avantis-are-you-exposed-list-item avantis-are-you-exposed-list-item-process-list'>🔹</i> Exclusive Data Privacy Manuals
                                </li>
                            </ul>

                            <div className="btn-box">
                                <Button className="default-btn" onClick={event => props.handleClick(event, 'avantis-privacy-family')}>
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing