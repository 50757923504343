import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import Pricing from "./components/Pricing";
import axios from "axios";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {v5 as uuid} from "uuid";
import BrandYourselfResponsiveTable from "./components/BrandYourselfResponsiveTable";
import ProgressBarContainer from "./components/ProgressBarContainer";
import AdditionalDataForm from "./components/AdditionalDataForm";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;

function App() {

    const { loginWithRedirect, isAuthenticated, error, isLoading, logout, user, getAccessTokenSilently } = useAuth0();
    const [avantisSubscriptionPlan, setAvantisSubscriptionPlan] = useState('');
    const [avantisSubscribed, setAvantisSubscribed] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [additionalData, setAdditionalData] = useState(null);
    const [freeScanData, setFreeScanData] = useState(null);
    const [freeScan, setFreeScan] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [token, setToken] = useState('');
    const [tokenError, setTokenError] = useState(false);
    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#fce883" },
                "::placeholder": { color: "#87bbfd" }
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee"
            }
        }
    }

    const handleAdditionalDataSubmitAfterEnrollment = async (values) => {
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/enroll-additional-members',
                headers: {Authorization: `${r}`},
                data: {
                    value: values,
                    userId: user.sub,
                    originalForms: additionalData
                }
            };
            axios.request(options).then(function (response) {
                setAdditionalData(response.data);
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(() => {
            console.log('Error Posting Form Data');
        });
    }

    const handleAdditionalDataSubmit = async (values) => {
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/user-data-submit',
                headers: {Authorization: `${r}`},
                data: {
                    value: values,
                    userId: user.sub,
                }
            };
            axios.request(options).then(function (response) {
                setAdditionalData(response.data);
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(() => {
            console.log('Error Posting Form Data');
        });
    }

    const handleCancelEnrollmentSubmit = async (e) => {
        e.preventDefault();
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/cancel-subscription',
                headers: {Authorization: `${r}`},
                data: {
                    userId: user.sub
                }
            };
            axios.request(options).then(function (response) {
                setUnsubscribed(response.data);
                alert("You have been unsubscribed!");
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(e => {
            console.log('error getting token for cancel subscription', e);
        });
    }

    const handlePaymentUpdateSubmit = async (e) => {
        e.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })
        if (!error) {
            getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            }).then(r => {
                const {id} = paymentMethod;
                const options = {
                    method: 'POST',
                    url: 'https://api.avantisprivacy.com/update-subscription-payment',
                    headers: {Authorization: `${r}`},
                    data: {
                        userId: user.sub,
                        id,
                    }
                };
                axios.request(options).then(function (response) {
                    console.log("success subscription update");
                }).catch(function (error) {
                    console.error(error);
                });
            }).catch(e => {
                console.log('error getting token for update plan', e);
            });
        } else {
            console.log(error.message);
        }
    }

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        })
        if (!error) {
            getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            }).then(r => {
                const {id} = paymentMethod;
                const options = {
                    method: 'POST',
                    url: 'https://api.avantisprivacy.com/pricing',
                    headers: {Authorization: `${r}`},
                    data: {
                        app_metadata: {
                            subscribed: true
                        },
                        userId: user.sub,
                        amount: 1,
                        id,
                        firstName: e.target.firstname.value,
                        lastName: e.target.lastname.value,
                        promo: e.target.promo.value,
                        email: e.target.email.value,
                        plan: avantisSubscriptionPlan === 'avantis-privacy-individual' ? 'individual' : 'family'
                    }
                };
                axios.request(options).then(function (response) {
                    setAvantisSubscribed(true);
                }).catch(function (error) {
                    console.error(error);
                });
            }).catch(e => {
                console.log('error getting token for update plan', e);
            });
        } else {
            console.log(error.message);
        }
    }

    const handleChangeEmailEvent = ((event) => {
        console.log('change email event');
        event.preventDefault();
        event.stopPropagation();
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/update-user-email',
                headers: {Authorization: `${r}`},
                data: {
                    email: event.target.email.value,
                    userId: user.sub
                }
            };
            axios.request(options).then(function (response) {
                loginWithRedirect();
            }).catch(function (error) {
                alert("Email update failed. Please try again later.")
            });
        }).catch(e => {
            alert("Email update failed. Please try again later.")
        })
    });

    const handleFreeScanResultsFinished = (() => {
        console.log('free scan is finished');
        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/update-app-metadata',
                headers: {Authorization: `${r}`},
                data: {
                    app_metadata: {
                        freeScan: false
                    },
                    userId: user.sub
                }
            };
            axios.request(options).then(function (response) {
                setFreeScan(response.data.freeScan);
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(e => {
            console.log('e getting token for update plan', e);
        })
    });

    const handleClick = async (event, plan) => {

        getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
        }).then(r => {
            const options = {
                method: 'POST',
                url: 'https://api.avantisprivacy.com/update-app-metadata',
                headers: {Authorization: `${r}`},
                data: {
                    app_metadata: {
                        subscriptionPlan: plan
                    },
                    userId: user.sub
                }
            };
            axios.request(options).then(function (response) {

                setAvantisSubscriptionPlan(response.data.subscriptionPlan);
            }).catch(function (error) {
                console.error(error);
            });
        }).catch(e => {
            console.log('e getting token for update plan', e);
        })
    };

    useEffect(() => {
        console.log('useEffect ran app.js');
        try {
            getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
            }).then((r => {
                const options = {
                    method: 'GET',
                    url: `https://${domain}/api/v2/users/${user.sub}`,
                    headers: {Authorization: `Bearer ${r}`}
                };
                // in my case I needed the app_metadata which held the users subscription status. But you can access anything you need similar to this
                axios.request(options).then(function (response) {
                    console.log('response from user request', response);
                    if (response.data['app_metadata'] && response.data['app_metadata']['subscriptionPlan']) {
                        setAvantisSubscriptionPlan(response.data['app_metadata']['subscriptionPlan']);
                    }
                    if ((response.data['app_metadata'] && response.data['app_metadata']['subscribed'])) {
                        setAvantisSubscribed(response.data['app_metadata']['subscribed']);
                    }
                    if ((response.data['app_metadata'] && response.data['app_metadata']['freeScan'])) {
                        setFreeScan(response.data['app_metadata']['freeScan']);
                    }
                    if ((response.data['app_metadata'] && response.data['app_metadata']['forms'])) {
                        setAdditionalData(response.data['app_metadata']['forms']);
                    }
                    if ((response.data['app_metadata'] && response.data['app_metadata']['unsubscribed'])) {
                        setUnsubscribed(response.data['app_metadata']['unsubscribed']);
                    }
                    if (response.data['app_metadata'] && response.data['app_metadata']['firstName'] &&
                        response.data['app_metadata']['lastName'] && response.data['app_metadata']['city'] &&
                        response.data['app_metadata']['state'] && response.data['app_metadata']['birthYear']
                    ) {
                        const freeScanDataObj = {
                            firstName: response.data['app_metadata']['firstName'],
                            lastName: response.data['app_metadata']['lastName'],
                            city: response.data['app_metadata']['city'],
                            state: response.data['app_metadata']['state'],
                            birthYear: response.data['app_metadata']['birthYear']
                        }
                        setFreeScanData(freeScanDataObj);
                    }
                    setToken(r);
                });
            })).catch(error => {
                setTokenError(true)
            });
        } catch (e) {
            console.log(e.message);
        }
    }, [getAccessTokenSilently, user?.sub]);

    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (error) {
        console.log('tokenError', tokenError);
      return <div>Oops... {error.message}</div>;
    }

    if (token) {
        if (isAuthenticated) {
            if (!user.email_verified && !avantisSubscriptionPlan) {
                return (
                    <div>
                        Hello {user.name}{' '}
                        <button onClick={() => logout({ returnTo: 'https://www.avantisprivacy.com' })}>
                            Log out
                        </button>
                        <div>{token}</div>
                        <button onClick={() => window.location.reload()}>
                            Check Verified Email
                        </button>
                    </div>
                );
            } else if (freeScan) {
                console.log('freeScanData', freeScanData);
                return (
                    <ProgressBarContainer callback={handleFreeScanResultsFinished} freeScanData={freeScanData} domain={domain}/>
                );
            } else if (!avantisSubscriptionPlan) {
                return (
                    <div>
                        <div>
                            {!user.email_verified ? <button onClick={() => window.location.reload()}>
                                Check Verified Email
                            </button> : null}
                        </div>
                        <Pricing handleClick={handleClick}/>
                        {
                            !!freeScanData &&
                            <div>Hello {user.name}{' '}
                                <button onClick={() => logout({ returnTo: 'https://www.avantisprivacy.com' })}>
                                Log out
                                </button>
                                <div>{token}</div>
                                <BrandYourselfResponsiveTable domain={domain}/>
                            </div>
                        }
                    </div>
                );
            } else if (!avantisSubscribed) {
                return (
                    <div>
                        <div>
                            {!user.email_verified ? <button onClick={() => window.location.reload()}>
                                Check Verified Email
                            </button> : null}
                        </div>
                        <form onSubmit={handlePaymentSubmit}>
                            <fieldset className="FormGroup">
                                <div className="FormRow">
                                    <label>
                                        First Name:
                                        <input required type="text" name="firstname" />
                                    </label>
                                    <label>
                                        Last Name:
                                        <input required type="text" name="lastname" />
                                    </label>
                                    <label>
                                        Email:
                                        <input required type="email" name="email" />
                                    </label>
                                    <label>
                                        PROMO CODE:
                                        <input type="text" name="promo" />
                                    </label>
                                    <CardElement options={CARD_OPTIONS}/>
                                </div>
                            </fieldset>
                            <button className="stripeButton">Pay</button>
                        </form>
                    </div>
                );
            } else if (!additionalData) {
                return (
                    <div>
                        <div>
                            {!user.email_verified ? <button onClick={() => window.location.reload()}>
                                Check Verified Email
                            </button> : null}
                        </div>
                        <AdditionalDataForm plan={avantisSubscriptionPlan} callback={handleAdditionalDataSubmit}/>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div>
                            {!user.email_verified ? <button onClick={() => window.location.reload()}>
                                Check Verified Email
                            </button> : null}
                        </div>
                        <button onClick={() => loginWithRedirect()}>
                            Change Password
                        </button>
                        <form onSubmit={handleChangeEmailEvent}>
                            <fieldset className="FormGroup">
                                <div className="FormRow">
                                    <label>
                                        New Email:
                                        <input required type="email" name="email" />
                                    </label>
                                </div>
                            </fieldset>
                            <button>Change Email</button>
                        </form>
                        <button type="submit">
                            Change Email
                        </button>
                        Hello {user.name}{' '}
                        <button onClick={() => logout({ returnTo: 'https://www.avantisprivacy.com' })}>
                            Log out
                        </button>
                        <CardElement options={CARD_OPTIONS}/>
                        <button onClick={(e) => handlePaymentUpdateSubmit(e)}>
                            Update Card Info
                        </button>
                        <button onClick={(e) => handleCancelEnrollmentSubmit(e)}>
                            Cancel Enrollment
                        </button>
                        <BrandYourselfResponsiveTable domain={domain}/>
                        {
                            avantisSubscriptionPlan === 'avantis-privacy-family' && additionalData.length < 4 &&
                            <AdditionalDataForm plan={avantisSubscriptionPlan} numEnrollment={additionalData.length} callback={handleAdditionalDataSubmitAfterEnrollment}/>
                        }
                    </div>
                );
            }
        } else if (!isAuthenticated) {
            console.log('should redirect');
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const plan = urlParams.get("subscription_plan");
            const email = urlParams.get("email")?.trim();
            const firstname = urlParams.get("firstname")?.trim();
            const lastname = urlParams.get("lastname")?.trim();
            const city = urlParams.get("city")?.trim();
            const state = urlParams.get("state")?.trim();
            const birthyear = urlParams.get("birthyear")?.trim();
            const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

            const id = uuid('Avantis Privacy', MY_NAMESPACE);
            let str;
            let subscriptionPlan;
            if (plan === 'family' || plan === 'individual') {
                subscriptionPlan = `avantis-privacy-${plan}`;
                str = subscriptionPlan;
            } else if (email && firstname && lastname && city && state && birthyear) {
                str = `${id}${email}${id}${firstname}${id}${lastname}${id}${city}${id}${state}${id}${birthyear}${id}`;
            }
            if (str) {
                loginWithRedirect({acr_values: str, screen_hint: 'signup'}).then(r => {});
            } else {
                loginWithRedirect();
            }
            return <div>Loading...</div>;
        }
    } else if (!isAuthenticated) {
        console.log('should redirect');
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const plan = urlParams.get("subscription_plan");
        const email = urlParams.get("email")?.trim();
        const firstname = urlParams.get("firstname")?.trim();
        const lastname = urlParams.get("lastname")?.trim();
        const city = urlParams.get("city")?.trim();
        const state = urlParams.get("state")?.trim();
        const birthyear = urlParams.get("birthyear")?.trim();
        const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

        const id = uuid('Avantis Privacy', MY_NAMESPACE);
        let str;
        let subscriptionPlan;
        if (plan === 'family' || plan === 'individual') {
            subscriptionPlan = `avantis-privacy-${plan}`;
            str = subscriptionPlan;
        } else if (email && firstname && lastname && city && state && birthyear) {
            str = `${id}${email}${id}${firstname}${id}${lastname}${id}${city}${id}${state}${id}${birthyear}${id}`;
        }
        if (str) {
            loginWithRedirect({acr_values: str, screen_hint: 'signup'}).then(r => {});
        } else {
            loginWithRedirect();
        }
        return <div>Loading...</div>;
    } else {
        return <div>Loading...</div>;
    }
}

export default App;
